/* src/components/ToggleSwitch.css */
.toggle-switch {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: var(--navbar-color);
    transition: color 0.3s ease;
  }
  
  .toggle-switch:focus {
    outline: none;
  }
  