.container {
    display: flex;
     padding-top: 8%; 
    /* padding-bottom: 3%; */
    width: 100%;
    
    background-color:var(--background-color);
   
  
  }
  
  .left-div {
    width: 44%;
    
    border-radius: 50px;
    border: 2px solid rgb(88, 88, 88);
    margin-right: 1%;
    background-image: linear-gradient(to right, black , rgb(22, 21, 21));;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 45px;
    padding-right: 30px;
    margin-left: 10%;
   

  }
  
  .right-div {
    width: 25%;
    background-image: linear-gradient(to right, black , rgb(22, 21, 21));;
    padding: 20px;
    border-radius: 50px;
    border: 2px solid grey;
    margin-right: 10%;
  }
  
  .header {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 38px;
    width: 56px;
    
  }
  
  .header-text {
    font-size: 20px;
    margin-left: 10px;
    color: aliceblue;
    line-height: 30px;
  }
  .header-text1{
    font-size: 16px;
    color: grey;
  }
  
  .icons {
    display: flex;
    align-items: center;
    margin-left: auto;
    
  }
  .icons-footer {
    display: flex;
    align-items: center;
    margin-left: 30%;
    
  }
  

  .icon{
    height: 20px; 
    width: 20px; 
    border: 2px solid grey;
    border-radius: 15px;
    margin-left: 10px;
    transition: border 0.1s;
    color: white;
    padding: 10px 10px;
  }
  .icon-f{
    height: 20px; 
    width: 20px; 
    border: 2px solid grey;
    border-radius: 15px;
    margin-left: 10px;
    transition: border 0.1s;
    color: var(--text-color);
    padding: 10px 10px;
  }
  
  .icon-button {
    padding: 20px 30px;
    text-decoration: none;
    border: 2px solid grey;
    border-radius: 15px;
    /* margin-left: 10px; */
    transition: border 0.1s;
    background: none;
    cursor: pointer;
    color: white;
  }
  .h-subtitle{
    font-size:48px;
    color: #9676ff;
    font-family: "Instrument Serif", serif;
    padding-bottom: 4%;
    
    /* margin-top: 1%; */
    padding-top: 0;
  }
  
  .icon:hover,
  .icon-button:hover {
    border: 5px solid grey;
  }
  .icon-f:hover,
  .icon-button:hover {
    border: 5px solid grey;
  }
  
  
  .title {
    font-size: 36px;
    margin-top: 20px;
    color: var(--text-color);
    font-weight: 500;
    line-height: 45px;
  }
  .title-p {
    font-size: 32px;
    margin-top: 20px;
    color: white;
    font-weight: 500;
    line-height: 45px;
    font-family: 'BlackMango';
  }
  .h-title{
    font-size: 36px;
    margin-top: 20px;
    font-family: 'Poppins';
    color: white;
    font-weight: 400;
    line-height: 45px;
  }
  
  .description {
    font-size: 18px;
    margin-top: 10px;
    color: grey;
    font-weight: 400;
    padding-bottom: 25px;
    line-height: 30px;
  }
  .description-p {
    font-size: 16px;
    margin-top: 10px;
    color: grey;
    font-weight: 400;
    padding-bottom: 20px;
  }
  
  .right-image {
    width: 100%;
    height: auto;
    border-radius: 50px;
  }
  @media (max-width: 450px) {
    .container{
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      max-width: 100%;
    } 
    .title {
      font-size: 34px;
      margin-top: 20px;
      color: var(--text-color);
      font-weight: 500;
      line-height: 45px;
  }
  .title_p {
    font-size: 16px;
    
    color: var(--text-color);
    
}
    .icons{
      margin-left: 0;
      margin-top: 3%;
    }
    .icon-button{
      padding: 20px;
      text-decoration: none;
    }
    .left-div{
      text-align: center;
      margin-top: 15%;
      padding-top: 15%;
      width:87%;
      margin-left: 2%;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;

    }
    .header{
      flex-direction: column;
      margin-top: 20px;
    }
    .header-text{
      text-align: center;
    }
    .h-title{
      font-size: 32px;
      text-align: center;
      margin-top: 56px;
    }
    .description {
      font-size: 16px;
      margin-top: 10px;
      color: grey;
      font-weight: 400;
      padding-bottom: 25px;
      margin-left: 45px;
      width: 74%;
    }
    .P_card{
      display: flex;
    }
  }
  