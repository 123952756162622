/* Entire CSS including existing and new styles */

/* Container Styles */
.project-gallery-container {
  background-color: #121212;
  color: white;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.gallery-title {
  font-size: 44px;
  margin-bottom: 10px;
}

.pg-subtitle {
  color: #5e3aff;
}

.subtitle-pg {
  font-size: 54px;
  margin-bottom: 40%;
  color: gray;
  font-family: 'Instrument Serif';
}

.gallery-description {
  font-size: 18px;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  max-width: 100%;
}

.project-gallery-card {
  display: flex;
  margin-top: 3%;
  /* flex-direction: column; */
  margin-left: 10%;
  align-items: center;
  gap: 50px;
  width: 80%;

}

/* Card Styles */
.cardd {
  background-color: #2C2C2C;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  width: 70%;

}

.cardd:hover {
  transform: translateY(-5px);
}

.cardd-image {
  width: 100%;
  height: auto;
  background-color: none;
}

.cardd-content {
  padding: 25px;
  /* text-align: center; */
}

.cardd-content h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: white;
  font-family: 'Poppins';
  font-weight: 500;
}

.cardd-content p {
  margin: 5px 0;
  font-size: 14px;
  color: white;
}

.play-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  color: black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.play-button:hover {
  background-color: black;
  color: white;
  transition: 0.5s ease;
}

.pg-button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  width: 80%;
  max-width: 1150px;
}

.popup-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.close-button {
  position: fixed;
  top: 10px;
  right: 15px;
  background: none;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
}



@media (max-width: 450px) {
  .project-gallery-card {
    display: flex;
    margin-top: 3%;
    flex-direction: column;
    
    align-items: center;
    gap: 50px;
    width: 80%;
  
  }
  
  /* Card Styles */
  .cardd {
    background-color: #2C2C2C;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
    width: 100%;
  
  }
  
  .cardd:hover {
    transform: translateY(-5px);
  }
  
  .cardd-image {
    width: 100%;
    height: auto;
    background-color: none;
  }
  
  .cardd-content {
    padding: 25px;
    /* text-align: center; */
  }
  
  .cardd-content h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: white;
    font-family: 'Poppins';
    font-weight: 500;
  }
  
  .cardd-content p {
    margin: 5px 0;
    font-size: 14px;
    color: white;
  }
  .play-button {
    display: none;
  }
  .popup-content {
    position: relative;
    width: 95%;
    max-width: 1150px;
  }
  
}