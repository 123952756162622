
    .container-i {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 800px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
    }
    .top-section-i {
      background-color: #fff0f5;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      padding: 32px;
      margin-bottom: 32px;
    }
    .logo-container-i {
      background-color: white;
      border-radius: 8px;
      padding: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logo-i {
      margin-bottom: 16px;
    }
    .company-name-i {
      font-size: 24px;
      font-weight: bold;
      color: #ff4500;
    }
    .opportunity {
      margin-top: 32px;
      text-align: center;
    }
    .opportunity h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .opportunity p {
      color: #666;
    }
    .bottom-section-i {
      background-color: #d2691e;
      width: 100%;
      border-radius: 8px;
      padding: 32px;
      color: white;
    }
    .bottom-section-i h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .bottom-section-i p {
      font-size: 18px;
    }
  