.containerrr {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 30px;
  padding-top: 3%;
}
.Pcard{
  display: flex;
  width: 34%;
  gap: 20%;
 
}

.boxx {
  position: relative;
  width: 32%;
  height: 500px;
  border-radius: 15px;
  overflow: hidden;
}

.image-containerrr {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-containerrr img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.hover-boxx {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--text-color);
  color: var(--background-color);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.image-containerrr:hover .hover-boxx {
  transform: translateY(0);
}

.hover-boxx h3 {
  margin: 0;
  font-size: 18px;
}

.hover-boxx p {
  margin: 5px 0 0;
  font-size: 14px;
}

@media (max-width: 450px) {
  .boxx {
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 30px;
  }
  .Pcard{
    display: grid;
    width: 34%;
    gap: 40px;
  }
  
  .containerrr {
    padding: 0;
    margin: 0;
  }
  .Pcard{
    display: grid;
    width: 34%;
    padding-top: 10%;
  }
  .card {
    --blur: 16px;
    --size: clamp(300px, 62vmin, 600px);
    width: var(--size);
    aspect-ratio: 4 / 3;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    color: #000;
    transform: translateZ(0);
  }
  
  .card__img {
    width: 100%;
    height: 81%;
    /* object-fit: cover; */
    transform: scale(calc(1 + (var(--hover, 0) * 0.25))) rotate(calc(var(--hover, 0) * -5deg));
    transition: transform 0.2s;
  }
  
  
}

/* Pop-up styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  width: 80%;
  max-width: 1200px;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  overflow-y: scroll;
  box-sizing: border-box;

}

.popup-image {
  max-width: 100%;
     height: auto;
  /* max-height: auto; */
  object-fit: contain;
  border-radius: 15px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.close-button:hover {
  color: #ff0000;
}
/* * {
  box-sizing: border-box;
}

body {
  display: grid;
  place-items: center;
  min-height: 100vh;
} */

.card {
  --blur: 16px;
  --size: clamp(300px, 62vmin, 600px);
  width: var(--size);
  aspect-ratio: 4 / 3;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  color: #000;
  transform: translateZ(0);
}

.card__img {
  width: 100%;
  height: 81%;
  /* object-fit: cover; */
  transform: scale(calc(1 + (var(--hover, 0) * 0.25))) rotate(calc(var(--hover, 0) * -5deg));
  transition: transform 0.2s;
}

.card__footer {
  padding: 0 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  display: grid;
  grid-template-row: auto auto;
  gap: 0.5ch;
  background: hsl(0 0% 30% / 0.5);
  backdrop-filter: blur(var(--blur));
  height: 24%;
  align-content: center;
}

.card__action {
  position: absolute;
  aspect-ratio: 1;
  width: calc(var(--size) * 0.1);
  bottom: 24%;
  right: 1.5rem;
  transform: translateY(50%)
    translateY(
      calc(
        (var(--size) * 0.4)
      )
    )
    translateY(calc(var(--hover, 0) * (var(--size) * -0.4)));
  background: purple;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
  background: hsl(0 0% 100% / 0.5);
/*   backdrop-filter: blur(calc(var(--blur) * 0.5)); */
  transition: transform 0.2s;
}

.card__action svg {
  aspect-ratio: 1;
  width: 50%;
}

.card__footer span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.card__footer span:nth-of-type(2) {
  font-size: 12px;
  color: white;
  font-weight: 400;
  width: 75%;
}

.card:is(:hover, :focus-visible) {
  --hover: 1;
}