/* src/Navbar.css */

.navbar {
    position: fixed;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 10px 20px;
    z-index: 1000;
  }
  .navbar2 {
    position: fixed;
    top: 48%;
    left: 112%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 10px 20px;
    z-index: 1000;
  }
  
  .navbar-content {
    display: flex;
    justify-content: space-around;
  }
  
  .navbar-item {
    padding: 10px 15px;
    text-decoration: none;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  .navbar-item-contact {
    padding: 10px 15px;
    text-decoration: none;
    color: white;
    font-size: 14px;
    transition: all 0.3s ease;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background: #7853f3;  
  }
  .navbar-item-home {
    padding: 10px 15px;
    text-decoration: none;
    color: var(--text-color)e;
    font-size: 14px;
    transition: all 0.3s ease;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
  }
  
  
  .navbar-item:hover {
    background: rgba(255, 255, 255, 0.3);
    color: #000000;
  }
  
  .navbar-item .svg-inline--fa {
    margin-right: 8px; /* Adjust as needed */
  }
  

  /* for contact nav bar */
  